@import "../../../../index.scss";

.sluglineExamplePart {
  margin: 45px auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  background-color: $gray;
  border: 1px solid $dark-gray;
  padding-bottom: 25px;
}

.sluglineExampleNumber {
  margin-top: 25px;
  margin-bottom: 20px;
  font-size: 24px;
}

.sluglineExample {
  font-family: "Courier Final Draft", Courier, monospace;
  font-size: 30pt;
  text-align: center;
}

.timeListContainer {
  display: flex;
  justify-content: space-around;
}

.timeList {
  list-style: disc;
}

.timeList label {
  border-bottom: 1px solid black;
  font-size: larger;
}

.listItems {
  margin-top: 10px;
  font-family: "Courier Final Draft", Courier, monospace;
}

@media screen and (max-width: 595px) {
  .sluglineExample {
    font-size: 6vw;
  }
}

@media screen and (max-width: 450px) {
  .listItems {
    font-size: 15px;
  }
}

@media screen and (max-width: 350px) {
  .sluglineExample {
    font-size: 21px;
  }
}
