$white: #f6f7f9;
$blue: #d6daec;
$light-blue: #c7d0ff;
$dark-blue: #92a1e8;
$darker-blue: #4e68ea;
$gray: #f1f1f1;
$dark-gray: #dadce6;
$footer-gray: #bcbcbc;
$darker-gray: #636469;
$orange: rgb(252, 156, 108);
$light-orange: rgb(251, 206, 183);

html,
body,
.app,
#root {
  height: 100%;
}

.app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // display: grid;
  // grid-template-rows: auto auto auto;
}

body {
  margin: 0;
  font-family: "Inconsolata", monospace;
  background-color: $dark-gray;
  line-height: 125%;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
