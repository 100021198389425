@import "../../index.scss";

.footer {
  background: repeating-linear-gradient(
    45deg,
    $gray,
    $gray 30px,
    white 30px,
    #ffffff 60px
  );
  display: flex;
  background-color: $dark-gray;
  border-top: 1px solid $footer-gray;
  padding: 5px 0;
  bottom: 0;
  height: fit-content;
  padding-left: 50px;
  align-items: center;
  min-width: 350px;

  .copyright {
    font-size: 13px;
    color: $darker-gray;
  }

  .footerLink {
    margin-left: 30px;
    font-size: 13px;
    color: $darker-gray;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 690px) {
  .footer {
    padding: 5px 7.5px;
    margin-top: 0;
  }
}

@media screen and (max-width: 400px) {
  .footer {
    .copyright {
      .websiteName {
        display: none;
      }
    }
  }
}
