@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@400&family=Raleway:wght@100&display=swap");
@import "../../index.scss";

/* maybe change container dimensions */

.homeContainer {
  padding: 10px;
  min-width: 340px;
}

.screentour-footer {
  border-top: 1px solid $dark-gray;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 20px;
}

.main {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
}

.container {
  max-width: 650px;
  min-width: 350px;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid black;
  border-top: none;
  border-bottom: none;

  a {
    color: $darker-blue;

    &:hover {
      color: $dark-blue;
    }
  }

  br {
  }

  #bold {
    font-weight: bold;
  }
  #italic {
    font-style: italic;
  }
  #underline {
    text-decoration: underline;
  }
  #courrier {
    font-family: "Courier Final Draft", Courier, monospace;
    font-size: 16px;
  }

  .dividerCourrier {
    margin-left: 20px;
    font-weight: 400;
    font-size: 22px;
    font-family: "Courier Final Draft", Courier, monospace;
  }
  #yellow {
    background-color: #ffec98;
  }
  #red {
    background-color: #ffc9c9;
  }
  #black {
    background-color: black;
  }
  #white {
    color: white;
  }

  #border {
    background-color: $dark-gray;
    width: 100%;
    height: 1px;
    margin-bottom: 30px;
    // add a margin top to get rid of <br>'s. Will have to do this in all blogs tho.
    // margin-top: 30px;
  }

  #screengradeBorder {
    background-color: $orange;
    opacity: 0.75;
    width: 100%;
    height: 1px;
    margin-bottom: 30px;
  }

  sup {
    font-size: 8pt;
  }

  .containerHeader {
    display: flex;
    justify-content: space-between;
    max-width: 650px;
    font-size: 14px;
    position: sticky;
    top: 50px;
    z-index: 9999;
    background-color: black;
    color: white;
    padding: 10px 20px;
    font-family: "Courier Final Draft", Courier, monospace;

    .startLink {
      float: right;

      &:hover {
        color: $dark-gray;
      }
    }
  }

  .blog {
    background-color: white;
    padding: 30px;
    padding-bottom: 0;
    max-width: 650px;
    overflow: hidden;

    .blogSubHeader {
      font-family: "Courier Final Draft", Courier, monospace;
      font-size: 24px;
      margin-bottom: 25px;
      display: flex;
      justify-content: center;
      border-bottom: 2px solid black;
      font-weight: 700;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }

    .blogText {
      font-family: "Inconsolata", monospace;
      font-size: 17px;
      line-height: 170%;

      .divider {
        font-weight: 400;
        font-size: 22px;
        font-weight: bold;
        // font-family: "Courier Final Draft", Courier, monospace;
      }

      .pictureContainer {
        margin: 45px 0;
        min-width: 290px;
        max-width: 500px;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid $dark-gray;
        // box-shadow: 0px 0px 2px 1px;

        .picture {
          max-width: 550px;
        }

        .pictureLabel {
          background-color: $gray;
          font-family: "Courier Final Draft", Courier, monospace;
          font-size: 14px;
          padding: 5px 0;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .gifContainer {
        display: flex;
        justify-content: center;
        margin: 45px 0;

        .gif {
          max-width: 550px;
          width: 500px;
          box-shadow: 0px 0px 2px 1px;
          // border-radius: 8px;
        }
      }

      @media screen and (max-width: 610px) {
        .gifContainer {
          .gif {
            width: 450px;
            width: 85vw;
          }
        }
      }

      @media screen and (max-width: 390px) {
        .gifContainer {
          .gif {
            width: 285px;
          }
        }
      }
    }
  }
}

.screengradePictureContainer {
  margin: 45px 0;
  border: 1px solid $gray;
  background-color: white;
  max-width: 500px;
  width: 100%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;

  .picture {
    max-width: 550px;
  }

  .pictureLabel {
    font-family: "Courier Final Draft", Courier, monospace;
    font-size: 13px;
    display: flex;
    justify-content: center;
    text-decoration: underline;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}

.screengradeContainer {
  justify-content: center;
  border-bottom: 1px solid black;
  border-bottom: none;
}

.screengradeHeader {
  padding: 10px 0;
  font-size: 14px;
  line-height: 125%;
  width: 100%;
  text-align: center;
  background-color: $orange;
  font-family: "Courier Final Draft", Courier, monospace;

  &:hover {
    opacity: 0.75;
    cursor: pointer;
  }
}

.closeHeader {
  border-bottom: 1px solid black;
  display: flex;
  justify-content: flex-end;
  line-height: 125%;
  width: 100%;
  text-align: center;
  background-color: $orange;
  font-family: "Courier Final Draft", Courier, monospace;

  .closeButton {
    font-size: 20px;
    padding: 10px 20px 10px 0;

    &:hover {
      opacity: 0.75;
      cursor: pointer;
    }
  }
}

.screengradeBlogText {
  font-family: "Inconsolata", monospace;
  font-size: 17px;
  line-height: 170%;
  background-color: $light-orange;
  padding: 30px;
  padding-bottom: 0;
  padding-top: 0;
  max-width: 650px;
  overflow: hidden;

  .divider {
    font-weight: 400;
    font-size: 22px;
    font-weight: bold;
    // font-family: "Courier Final Draft", Courier, monospace;
  }
}

.localGlossary {
  background-color: black;
  color: white;
}

.localGlossaryHeader {
  padding: 10px 20px 0 20px;
  font-family: "Courier Final Draft", Courier, monospace;
  font-size: 14px;
  text-align: center;
}

.localGlossaryList {
  font-family: "Inconsolata", monospace;
  line-height: 125%;
  font-size: 12px;
  padding-right: 20px;
}

.localGlossaryList li {
  margin-top: 10px;
}

.definition {
  padding-top: 5px;
}

.localGlossaryFooter {
  font-family: "Inconsolata", monospace;
  font-size: 10px;
  text-align: center;
}

.screengradeHeader,
.closeButton {
  display: inline-block;
}

//  MEDIA QUERYS

@media screen and (max-width: 645px) {
  .pictureLabel {
    width: 100%;
    overflow: hidden;
    float: left;
  }
}

@media screen and (max-width: 693px) {
  .screengradePictureContainer {
    max-height: 400px;
  }
}

@media screen and (min-width: 560px) {
  .picture {
    transform: translateX(-25px);
  }
}

@media screen and (max-width: 560px) {
  .picture {
    transform: translate(-85px) scale(1);
  }
}

@media screen and (max-width: 480px) {
  .picture {
    transform: translate(-90px) scale(0.95);
  }
}

@media screen and (max-width: 460px) {
  .picture {
    transform: translate(-105px) scale(0.9);
  }
}

@media screen and (max-width: 430px) {
  .picture {
    transform: translate(-115px) scale(0.85);
  }
}

@media screen and (max-width: 415px) {
  .picture {
    transform: translate(-125px) scale(0.8);
  }
}

@media screen and (max-width: 395px) {
  .picture {
    transform: translate(-135px) scale(0.75);
  }
}

@media screen and (max-width: 375px) {
  .picture {
    transform: translate(-140px) scale(0.725);
  }
}

@media screen and (max-width: 690px) {
  .main {
    padding: 0;
    padding-top: 50px;
  }

  .container {
    margin: 0;
    max-width: 690px;
    border: none;

    .containerHeader {
      max-width: 690px;
    }

    .blog {
      max-width: 690px;
    }
  }
}
