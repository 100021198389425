@import "../../../index.scss";

// ANIMATIONS
/*
@keyframes colorChange {
  0% {
    // background-color: $dark-blue;
    color: black;
  }
  25% {
    // background-color: $light-orange;
    color: $orange;
  }
  50% {
    // background-color: $dark-blue;
    color: black;
  }

  75% {
    // background-color: $light-orange;
    color: $darker-blue;
  }
  100% {
    // background-color: $dark-blue;
    color: black;
  }
}
*/

.notFoundContainer {
  margin-right: auto;
  margin-left: auto;
  margin-top: 35px;

  font-family: "Courier Final Draft", Courier, monospace;
  background-color: white;
  text-align: center;
  min-width: 420px;
  max-width: 420px;
  height: 420px;
  border: 1px solid $footer-gray;
  padding: 50px 0;
  /* disables highlighting */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .notFoundSlugline {
    margin: 0 60px;
    font-size: 10px;
    display: flex;
    font-weight: bold;
    max-width: 325px;
  }

  .notFoundDescription {
    margin: 0.25em 0;
    margin-left: 60px;
    margin-right: 40px;
    line-height: 100%;
    font-size: 10px;
    float: left;
    text-align: left;
  }
}
