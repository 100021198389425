@import "../../../../../index.scss";

.boldSubHeader {
  font-family: "Courier Final Draft", Courier, monospace;
  font-size: 15pt;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.italicsSubHeader {
  font-family: "Courier Final Draft", Courier, monospace;
  font-size: 15pt;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.underlineSubHeader {
  font-family: "Courier Final Draft", Courier, monospace;
  font-size: 15pt;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.superimposeExamplePart {
  margin: 45px auto;
  display: flex;
  justify-content: center;
  max-width: 500px;
  display: flex;
  background-color: $gray;
  border: 1px solid $dark-gray;
  padding: 25px 0;
}

.superimposeExample {
  font-family: "Courier Final Draft", Courier, monospace;
  font-size: 35px;
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
}

#allTextDecs {
  font-weight: bold;
  text-decoration: underline;
  font-style: italic;
}

@media screen and (max-width: 550px) {
  .superimposeExample {
    font-size: 6vw;
  }
}

@media screen and (max-width: 390px) {
  .superimposeExample {
    font-size: 20px;
  }
}
